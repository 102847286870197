/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* below file are not to be included here as they get transpiled into SCSS,
   below css files can be found in html header and are injected using ajax to manage tenant styles */
/*@import "../../wwwroot/tenant-resources/default/base.css";
@import "../../wwwroot/tenant-resources/warwickshire-college/base.css";*/




/* Please add any GLOBAL ruel in this file */
/* **************************************  */

.mat-mdc-card {
  padding: 16px;
}

.mat-mdc-card.mdc-card {
   display: block;
}

/* rules for h tags on reg form only */
h2.reg {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    font-size: 24px;
    margin-bottom: 8px;
    color: #000000DE;
}

h3.reg {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    font-size: 20px;
    margin-bottom: 8px;
    color: #000000DE;
}